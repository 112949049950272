* {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
  }
}

.MuiButtonBase-root.MuiFab-root.MuiFab-circular.MuiFab-sizeMedium.MuiFab-default {
  & {
    overflow: hidden;
  }
}

:root {
  --background-color: transparent;
  --color: black;
  --chart-menu-background: #fff;
  --nav-background-color: transparent;
  --search: black;
  --main-logo: #0073a8;
  --main-logo-icon: black;
}

$theme: dark;

#menutabs {
  background-color: var(--background-color);
}

#breadcrumbcustom {
  background-color: var(--background-color);
}

#error_date {
  color: red;
}

.menutab {
  color: var(--color);
}

.mainlogo {
  color: var(--main-logo);
}

.mainlogoicon {
  color: var(--main-logo-icon);
}

#header-nav {
  background-color: var(--nav-background-color);
}

.apexcharts-menu-open {
  background: var(--chart-menu-background) !important;
}

#sidebar-nav {
  background-color: var(--nav-background-color);
  color: var(--search);
}

#search,
#header-search {
  color: var(--search);
}

.link {
  color: inherit;
}

#cards_user {
  margin-bottom: 22px;
}
